import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Alert, Select, message, Table } from 'antd'
import { dateFormatHandler } from '../../../functions'
import { strapiURL } from '../../../config'
import axios from 'axios'
import cookie from 'react-cookies'
import EmployerSaveSearchModal from '../../Modals/employerSaveSearch'
import { emailFrequency } from '../../../data'

const { Option } = Select

const ResumeAlertsComponent = ({ userProfile }) => {
  const [multipleResume, setMultipleResume] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [searches, setSearches] = useState([])
  const [editItem, setEditItem] = useState(null)
  const [loading, setLoading] = useState(true)

  const loadUserSearches = () => {
    let id = userProfile?.id
    let currentUser = cookie.load('user')
    if (currentUser) {
      id = currentUser.user.id
    }

    axios
      .get(strapiURL + `/employer-searches/employer/${id}`)
      .then(res => {
        if (res?.data?.length) {
          let _data = res.data
          _data.forEach(item => {
            item.key = item.id
          })
          _data = _data.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          )
          setSearches(_data)
        }
      })
      .catch(() => {
        message.error('Something went wrong')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (userProfile) {
      loadUserSearches()
    }
  }, [userProfile])

  const runSearch = record => {
    let runningSearch = {
      ...record,
    }
    navigate('/employer-dashboard/resume/search', {
      state: {
        runningSearch,
      },
    })
  }

  const rowSelection = {
    selectedRowKeys: multipleResume.map(item => item.id),
    onChange: (selectedRowKeys, selectedRows) => {
      setMultipleResume(selectedRows)
    },
  }

  const updateSearch = (searchId, value) => {
    axios
      .put(strapiURL + `/employer-searches/${searchId}`, {
        frequency: value,
      })
      .then(res => {
        message.success('Search updated')
      })
      .catch(err => {
        console.log(err, 'check err')
        message.error('Something went wrong.')
      })
  }

  const deleteSearch = searchId => {
    axios
      .delete(strapiURL + `/employer-searches/${searchId}`)
      .then(() => {
        let _copyData = [...searches]
        _copyData = _copyData.filter(item => item.id !== searchId)
        setSearches(_copyData)
        message.success('Search deleted')
      })
      .catch(err => {
        console.log(err, 'check err')
        message.error('Something went wrong.')
      })
  }

  const resumeCols = [
    {
      title: 'Search Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => {
        return (
          <p className="text-xs font-bold font-sans mb-0">
            {record?.name || 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      render: (text, record, index) => {
        return (
          <p className="text-last text-location leading-0 ml-1 mb-0">
            {record?.keyword || 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',

      render: (text, record, index) => {
        return (
          <Select
            onChange={val => updateSearch(record.id, val)}
            className="font-sans text-last text-merlot w-28"
            defaultValue={record.frequency}
          >
            {emailFrequency.map((freq, index) => {
              return (
                <Option
                  className="font-sans text-last text-merlot"
                  value={freq}
                  key={index}
                >
                  {freq}
                </Option>
              )
            })}
          </Select>
        )
      },
    },

    {
      title: 'Created on',
      dataIndex: 'published_at',
      key: 'published_at',
      render: (text, record, index) => {
        return (
          <p className="text-last text-location leading-0 ml-1 mb-0">
            {dateFormatHandler(record?.created_at) || 'Not Specified'}
          </p>
        )
      },
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        return (
          <div className="flex items-center">
            <span
              onClick={() => {
                setEditItem({
                  ...record,
                  location: record.location
                    ? record.location?.placePredict
                    : undefined,
                })
                setModalOpen(true)
              }}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              Edit
            </span>
            <div className="h-4 w-[0.5px] bg-merlot mx-2" />
            <div
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
              onClick={() => runSearch(record)}
            >
              <span>Run</span>
            </div>
            <div className="h-4 w-[0.5px] bg-merlot mx-2" />
            <span
              onClick={() => deleteSearch(record.id)}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              Delete
            </span>
          </div>
        )
      },
    },
  ]
  return (
    <div className="mt-6 lg:mt-0">
      <h1 className="font-playfair text-headingThree font-normal text-merlot">
        Saved Resume Searches & Alerts
      </h1>
      <Alert
        className="alert-info"
        description="Below are resume alerts you created. The resume alert emails you receive from MedReps contain candidates matching these searches. Run any of your saved searches by clicking below, or change the frequency of your resume alert emails."
        type="info"
        showIcon
      />
      <div className="rounded-lg bg-white shadow-profileCard mt-8">
        <div className="flex px-4 py-4 border-b border-bgSecondaryButton justify-between">
          <div className="flex items-center">
            <img src="/icons/icon-application.svg" className="mb-0" />
            <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
              Results ({searches?.length || 0})
            </h1>
          </div>
        </div>
        {loading ? (
          <div
            className="w-full h-[200px] bg-no-repeat bg-cover"
            style={{
              backgroundImage: `url(/loaders/resume-table-loader.png)`,
            }}
          />
        ) : (
          <Table
            scroll={{ x: 'min-content' }}
            className="hide-table-header-background hide-table-header-cols-border table-with-pagination table-with-checkbox"
            columns={resumeCols}
            dataSource={searches}
            bordered={false}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
          />
        )}
      </div>
      <EmployerSaveSearchModal
        editItem={editItem}
        setEditItem={setEditItem}
        userProfile={userProfile}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        currentData={searches}
        setCurrentData={setSearches}
      />
    </div>
  )
}
export default ResumeAlertsComponent
