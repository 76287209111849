import React from 'react'
import Layout from '../../../components/employerDashboard/layout'
import ResumeAlertsComponent from '../../../components/employerDashboard/resume/searches'

const ResumeSavedSearches = ({ location }) => {
  return (
    <Layout title="Resume Search | MedReps.com" location={location}>
      <ResumeAlertsComponent />
    </Layout>
  )
}
export default ResumeSavedSearches
